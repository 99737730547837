<template>
  <div class="bind_otp_bind">
    <div class="bind_otp_bind_icons mt_3em mb_2em">
      <img src="@/assets/images/login/ic_security_center_key.webp" />
    </div>
    <div class="bind_otp_bind_info mb_3em">
      <div>
        请奖16位密钥记录在纸上，并保存在安全的地方如遇手机丢失，你可以通过该密钥回复你的谷歌验证
      </div>
    </div>
    <div class="bind_otp_bind_link">
      <div>{{ securityKey }}</div>
      <div class="bind_otp_bind_link_copy textBtn ta_right" v-clipboard:copy="securityKey" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
        复制
      </div>
    </div>
    <div class="textBtn ta_center mt_2em" @click="goToHelp">查看教程</div>
    <button class="bind_otp_bind_next btn oval mb_4rem" @click="goNextPage">
      下一步
    </button>
    <iframe ref="download" style="display:none;"></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BindOTPBind",
  computed: {
    ...mapState("auth", ["googleOTPInfo"]),
    securityKey() {
      return this.googleOTPInfo.googleSecret || "";
    },
  },
  methods: {
    handleCopySuccess(e) {
      console.log("copy success", e);
    },
    handleCopyError(e) {
      console.log(e);
    },
    goNextPage() {
      this.$router.push("/bindOTP/confirm");
    },
    goToHelp() {
      this.$router.push("/bindOTP/help");
    },
  },
};
</script>

<style lang="scss" scoped>
.bind_otp_bind {
  padding: 0 1em;
  position: relative;
  &_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 3em;
    }
    img:nth-child(2) {
      width: 2em;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  &_info {
    &_copy {
      margin-top: 0.8rem;
      color: var(--gray);
    }
  }
  &_link {
    border: 1px solid var(--light-orange);
    background: var(--very-light-orange);
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_copy {
      min-height: 2em;
      line-height: 2em;
    }
  }
  &_next {
    position: fixed;
    width: calc(100% - 2rem);
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
  }
}
</style>
